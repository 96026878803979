<template>
  <div
    v-if="state === states.READY"
    class="grid gap-4 lg:grid-cols-2"
  >
    <BaseCard>
      <BaseCardHeader
        bg="bg-secondary"
        class="mb-6"
      >
        <template slot="icon">
          <Calculator />
        </template>
        Usage
      </BaseCardHeader>
      <div>
        <section class="mt-8">
          <h2 class="inline-flex p-1 text-sm rounded bg-primary-200">
            {{ paygSubscription.plan.name }}
          </h2>
          <p class="mt-3 text-3xl font-bold leading-none">
            <span>
              {{ formatted(paygSubscription.fixed.unit_amount, paygSubscription.fixed.currency) }}
              <span class="text-lg font-normal text-gray-600">/year</span>
              <small
                v-if="paygSubscription.fixed.tax_percent"
                class="text-xs font-normal text-gray-600"
              >
                + {{ paygSubscription.fixed.tax_percent }}% VAT
              </small>
            </span>
          </p>
          <ul class="mt-6 space-y-2">
            <li v-if="paygSubscription.fixed.coupon">
              <p class="mt-3 leading-none text-sm text-gray-600">
                Discount:
                <span v-if="paygSubscription.fixed.coupon.amount_off">
                  {{ formatted(paygSubscription.fixed.coupon.amount_off, paygSubscription.fixed.currency) }} off
                </span>
                <span v-else-if="paygSubscription.fixed.coupon.percent_off">
                  {{ paygSubscription.fixed.coupon.percent_off }}% off
                </span>
                <span v-if="paygSubscription.fixed.coupon.duration == 'forever'">
                  every year
                </span>
                <span v-else-if="paygSubscription.fixed.coupon.duration == 'once'">
                  this year
                </span>
              </p>
            </li>
            <li>
              <p class="mt-3 leading-none text-gray-600">
                Renews on <strong>{{ formatDate(fromUnixTime(paygSubscription.fixed.current_period_end)) }}</strong>
              </p>
            </li>
          </ul>
          <h4 class="mt-12 mb-1 text-lg font-medium">
            Credit usage
          </h4>
          <h5 class="leading-none text-gray-600">
            Current year
          </h5>
          <div class="flex flex-col mt-6">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded">
                  <table
                    width="100%"
                    class="min-w-full divide-y divide-gray-300"
                  >
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-xs font-medium tracking-wider text-left text-gray-600 uppercase"
                        >
                          Credits
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-xs font-medium tracking-wider text-right text-gray-600 uppercase"
                        >
                          Used
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-xs font-medium tracking-wider text-right text-gray-600 uppercase"
                        >
                          Included
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-xs font-medium tracking-wider text-right text-gray-600 uppercase"
                        >
                          Bought
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-xs font-medium tracking-wider text-right text-gray-600 uppercase"
                        >
                          Remaining
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="px-3 py-4 text-left whitespace-nowrap">
                          Candidates
                        </th>
                        <td
                          class="px-3 py-4 text-sm text-right text-gray-700 whitespace-nowrap"
                        >
                          {{ numberFormat(paygSubscription.candidates.recorded_count) }}
                        </td>
                        <td
                          class="px-3 py-4 text-sm text-right text-gray-700 whitespace-nowrap"
                        >
                          {{ numberFormat(paygSubscription.candidates.extra_credits + paygSubscription.plan.candidate_credits) }}
                        </td>
                        <td
                          class="px-3 py-4 text-sm text-right text-gray-700 whitespace-nowrap"
                        >
                          {{ numberFormat(paygSubscription.usage.candidates_excluded_count) }}
                        </td>
                        <td
                          class="px-3 py-4 text-sm text-right text-gray-700 whitespace-nowrap"
                        >
                          {{ numberFormat(paygSubscription.candidates.remaining_credits) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p
                  v-if="paygSubscription.candidates.extra_credits > 0"
                  class="text-sm mt-3"
                >
                  Total candidate credits are {{ numberFormat(paygSubscription.plan.candidate_credits) }} credits from your plan, plus {{ numberFormat(paygSubscription.candidates.extra_credits) }} extra credits.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section class="mt-12">
          <h4 class="mb-1 text-lg font-medium">
            Supplementary charges
          </h4>
          <h5 class="leading-none text-gray-600">
            Current month
          </h5>
          <p class="mt-3 leading-none text-gray-600">
            Payment will be taken on <strong>{{ formatDate(fromUnixTime(paygSubscription.usage.current_period_end)) }}</strong>
            <small v-if="paygSubscription.usage.tax_percent"> + {{ paygSubscription.usage.tax_percent }}% VAT</small>
          </p>

          <div class="flex flex-col mt-6">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded">
                  <table
                    width="100%"
                    class="min-w-full divide-y divide-gray-300"
                  >
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-xs font-medium tracking-wider text-left text-gray-600 uppercase"
                        >
                          Credits
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-xs font-medium tracking-wider text-right text-gray-600 uppercase"
                        >
                          Per credit
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-xs font-medium tracking-wider text-right text-gray-600 uppercase"
                        >
                          Used
                        </th>
                        <th
                          scope="col"
                          class="py-3.5 px-3 text-xs font-medium tracking-wider text-right text-gray-600 uppercase"
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="px-3 py-4 text-left whitespace-nowrap">
                          Candidates
                        </th>
                        <td
                          class="px-3 py-4 text-sm text-right text-gray-700 whitespace-nowrap"
                        >
                          {{ formattedDecimal(paygSubscription.candidates.unit_amount, paygSubscription.candidates.currency) }}
                        </td>
                        <td
                          class="px-3 py-4 text-sm text-right text-gray-700 whitespace-nowrap"
                        >
                          {{ numberFormat(paygSubscription.usage.candidates_excluded_count) }}
                        </td>
                        <td
                          class="px-3 py-4 text-sm font-bold text-right text-gray-700 whitespace-nowrap"
                        >
                          {{ formattedDecimal(paygSubscription.candidates.unit_amount * paygSubscription.usage.candidates_excluded_count, paygSubscription.candidates.currency) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Basecard>
    <BaseCard>
      <BaseCardHeader
        bg="bg-secondary"
        class="mb-6"
      >
        <template slot="icon">
          <Upgrade />
        </template>
        Upgrade
      </BaseCardHeader>
      <div v-if="upgradePlan">
        <div class="flex justify-between">
          <div>
            <h2 class="inline-flex p-1 text-sm rounded bg-primary-200">
              {{ upgradePlan.name }}
            </h2>
            <p class="mt-3 text-3xl font-bold leading-none">
              {{ formatted(upgradePlan.products.fixed.price.unit_amount, upgradePlan.products.fixed.price.currency) }}
              <span class="text-lg font-normal text-gray-600">/year</span>
              <span class="text-xs font-normal text-gray-600"> + tax</span>
            </p>
          </div>
          <div>
            <PAYGUpgradeButton
              :plan="upgradePlan"
              :billed="formatted(upgradePlan.products.fixed.price.unit_amount, upgradePlan.products.fixed.price.currency)"
            />
            <p class="text-sm mt-3">
              or
              <router-link
                :to="{ name: 'settings-payg-change-plan' }"
                class="underline"
              >
                see all our plans
              </router-link>
            </p>
          </div>
        </div>
        <h4 class="mt-8 mb-3 text-lg font-medium">
          Candidates
        </h4>
        <div class="flex items-center justify-between">
          <div class="w-full border rounded shadow">
            <div class="py-3.5 px-3 bg-gray-50 rounded">
              <h5 class="text-xs font-medium tracking-wider text-left text-gray-600 uppercase">
                Currently
              </h5>
            </div>
            <ul class="p-3">
              <li>
                {{ numberFormat(paygSubscription.plan.candidate_credits) }} included
              </li>
              <li>
                Then {{ formatted(paygSubscription.candidates.unit_amount, paygSubscription.candidates.currency) }}/each
              </li>
            </ul>
          </div>
          <div class="flex flex-col items-center justify-center mx-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
              />
            </svg>
          </div>
          <div class="w-full border rounded shadow bg-primary-200">
            <div class="py-3.5 px-3 border-b border-white">
              <h5 class="text-xs font-medium tracking-wider text-left uppercase">
                Upgrade to
              </h5>
            </div>
            <ul class="p-3">
              <li>
                <strong class="text-xl">{{ numberFormat(upgradePlan.candidate_credits) }}</strong> included
              </li>
              <li>
                Then <strong class="text-xl">{{ formatted(upgradePlan.products.candidates.price.unit_amount, upgradePlan.products.candidates.price.currency) }}/each</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else>
        <h3 class="text-xl font-medium">
          Get in touch to upgrade
        </h3>
        <p class="my-4">
          You’re on our highest-tier plan!
        </p>
        <p class="my-4">
          To talk through your upgrade options, contact our support team.
        </p>
        <BaseButton
          :to="supportUrl"
        >
          Contact Support
        </BaseButton>
      </div>
    </BaseCard>
  </div>
  <div v-else-if="state === states.LOADING">
    <Loader />
  </div>
  <div v-else-if="state === states.ERROR">
    <BaseErrorBlock />
  </div>
</template>

<script>
import Calculator from '@components/Icons/Calculator'
import Loader from '@components/Loader'
import PAYGUpgradeButton from '@components/Settings/PAYGUpgradeButton'
import Upgrade from '@components/Icons/Upgrade'

import plansApi from '@api/plans'
import states from '@api/states'
import { formatCurrencyWhole, formatCurrency } from '@utils/currency'
import { formatDate } from '@utils/formatDate'
import { fromUnixTime } from 'date-fns'
import { mapGetters } from 'vuex'
import { numberFormat } from '@utils/numberFormat'

export default {
  components: {
    Calculator,
    Loader,
    PAYGUpgradeButton,
    Upgrade
  },

  data() {
    return {
      states,
      formatDate,
      fromUnixTime,
      numberFormat,

      supportUrl: process.env.VUE_APP_SUPPORT_URL,

      plans: null
    }
  },

  computed: {
    ...mapGetters({
      paygSubscription: 'subscriptions/paygSubscription',
      hasSubscriptionLoaded: 'subscriptions/hasSubscriptionLoaded',
      jobsCount: 'jobs/jobsCount',
      haveJobsLoaded: 'jobs/haveJobsLoaded'
    }),

    /**
     * @return {string}
     */
    state() {
      // if (this.error) {
      //   return states.ERROR
      // }
      if (!this.plans || !this.paygSubscription || !this.haveJobsLoaded || !this.hasSubscriptionLoaded) {
        return states.LOADING
      }

      return states.READY
    },

    /**
     * Return the next plan to upgrade to
     *
     * @return {Object}
     */
    upgradePlan() {
      let nextLevel = 1
      if (this.paygSubscription) {
        nextLevel = this.paygSubscription.plan.level + 1
      }

      return this.allPlansForCurrency.find(plan => plan.level === nextLevel)
    },

    /**
     * Returns a list of plans with price for the current currency, and with
     * the pries according to type
     */
    allPlansForCurrency() {
      const currency = this.paygSubscription.fixed.currency

      return this.plans.map(plan => {
        plan.products.fixed.price = plan.products.fixed.prices.find(price => price.currency === currency)
        plan.products.candidates.price = plan.products.candidates.prices.find(price => price.currency === currency)

        return plan
      })
    }
  },

  mounted() {
    this.loadPlans()
    this.$store.dispatch('subscriptions/getSubscriptions')

    if (!this.haveJobsLoaded) {
      this.$store.dispatch('jobs/getJobs')
    }
  },

  methods: {
    /**
     * Load in products if missing
     */
    loadPlans() {
      plansApi.paygIndex()
        .then(response => {
          this.plans = response.data.plans
        })
        .catch(error => {
          this.error = error
          throw error
        })
    },

    /**
     * @param {Number} amount
     * @param {string} currency
     * @return {string}
     */
    formatted(amount, currency) {
      if (!currency) {
        return amount / 100
      }
      return formatCurrencyWhole(amount / 100, currency)
    },

    /**
     * @param {Number} amount
     * @param {string} currency
     * @return {string}
     */
    formattedDecimal(amount, currency) {
      if (!currency) {
        return amount / 100
      }
      return formatCurrency(amount / 100, currency)
    }
  }
}
</script>
