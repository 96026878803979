<template>
  <div>
    <SettingsNavigation />

    <BaseWrapper>
      <PAYGUsageCard />
    </BaseWrapper>
  </div>
</template>

<script>
import SettingsNavigation from '@components/Settings/SettingsNavigation'
import PAYGUsageCard from '@components/Settings/PAYGUsageCard'

export default {
  components: {
    SettingsNavigation,
    PAYGUsageCard
  }
}
</script>
