/**
 * Format Currency (whole)
 */
export const formatCurrencyWhole = (amount, currencyCode, locale) => {
  if (!locale) {
    locale = 'en-GB'
  }
  return parseFloat(amount).toLocaleString(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0
  })
}

/**
 * Format Currency
 */
export const formatCurrency = (amount, currencyCode, locale) => {
  if (!locale) {
    locale = 'en-GB'
  }
  return parseFloat(amount).toLocaleString(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 2
  })
}

/**
 * Format Currency
 */
export const formatCurrencyAuto = (amount, currencyCode, locale) => {
  if (amount % 1 === 0) {
    return formatCurrencyWhole(amount, currencyCode, locale)
  }
  return formatCurrency(amount, currencyCode, locale)
}
