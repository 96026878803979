<template>
  <BaseContainer>
    <PAYGUsageSettings />
  </BaseContainer>
</template>

<script>
import PAYGUsageSettings from '@components/Settings/PAYGUsageSettings'

export default {
  page: {
    title: 'Usage'
  },

  components: {
    PAYGUsageSettings
  }
}
</script>
